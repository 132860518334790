.form-labels {
  font-size: 14px !important;
}

.display-address{
  display: -webkit-box;
  max-width: 300px;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}


.print-element{
  display: none;
}

@media print {
  .print-element{
    display: block;
    padding: 30px;
  }
}

.table-row-middle-align {
  vertical-align: middle;
}

@media only screen and (max-width: 600px) {
  li.nav-item {
    display: flex;
    flex-basis: 33.33%;
    width: 33%;
  }

  .table-overview, .table-by-user{
    width: 1200px;
  }

  .table-by-day{
    width: 900px;
  }
}

.superscript-plus-one{
  color: #F26930;
}

.display-purpose{
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
  overflow: hidden;
  text-overflow: ellipsis;
}

.date-display{
  color: #ff7043;
  cursor: pointer;
}

.date-display:hover{
  text-decoration: underline;
}

.time-display{
  color: black;
  cursor: pointer;
  text-decoration: underline;
}


.highlight-time-display{
  color: #ff7043;
  cursor: pointer;
  font-weight: bold;
  text-decoration: underline;
}

.display-title{
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.circular-progress {
  position: relative;
  width: 15px;  /* Set the size of the circle */
  height: 15px; /* Set the size of the circle */
  border-radius: 50%;
  display: inline-block;
}

.circle {
  width: 15px !important;
  height: 15px !important;
  border-radius: 50%;
  background: conic-gradient(green 0deg, green 0deg, #f0f0f0 0deg);
}



