.table-header{
    cursor: pointer;
}

.inherit-position{
    position: inherit;
    bottom: 0
}

.relative-position{
    position: relative;
    bottom: -50%;
}

.today-attendance-dashboard{
    height: 325px;
}

@media only screen and (max-width: 600px) {
    .today-attendance-dashboard{
        height: 350px;
    }
}
