.modal {
  z-index: 2000 !important;
}
.modal-backdrop {
  z-index: 2000 !important;
}
.modal-title {
  font-size: 1rem !important;
}

.sample-format{
  text-decoration: underline;
  color: blue;
  cursor: pointer;
}

.profile-avatar:hover{
  transform: scale(3);
  transition: 0.3s;
}
