.submodule:hover{
    cursor: pointer;
    color: #27699C;
    text-decoration: underline;
}

.popover-arrow{
    z-index: 99;
}

.popover-class{
    z-index: 2000;
}

.w-25{
    width: 28% !important;
}

.search-bar{
    font-size: 90%;
}

.module{
    margin-left: 20px;
    font-weight: bold;
}

.users{
    margin-left: 20px;
}

/*.feature:hover{*/
/*    !*cursor: pointer;*!*/
/*    color: #27699C;*/
/*    !*text-decoration: underline;*!*/
/*}*/

.breadcrumbs{
    font-size: 95%;
    padding-top: -2px;
    color: gray;
}

.feature-list:hover{
    background-color: #e4e7eb;
}

.view-all{
    font-size: 95%;
    color: #F2693D;
}

.view-all:hover{
    text-decoration: underline;
    cursor: pointer;
}

.icon{
    margin: auto;
}

.sub-module:hover{
    background-color: #e4e7eb;
}

.search-name{
    color: #F2693D;
}