.form-labels {
  font-size: 14px !important;
}

.display-address{
  display: -webkit-box;
  max-width: 300px;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}


.print-element{
  display: none;
}

@media print {
  .print-element{
    display: block;
    padding: 30px;
  }
}

.table-row-middle-align {
  vertical-align: middle;
}

@media only screen and (max-width: 600px) {
  li.nav-item {
    display: flex;
    flex-basis: 33.33%;
    width: 33%;
  }

  .table-overview, .table-by-user{
    width: 1200px;
  }

  .table-by-day{
    width: 900px;
  }
}