@media only screen and (max-width: 600px) {
      ul.generate-payroll-tabs li.nav-item {
        display: flex;
        flex-basis: 25%;
        width: 25%;
      }

    ul.generate-payroll-tabs li.nav-item button{
        font-size: 80%;
    }

    .nav-tabs .nav-link, .nav-tabs .nav-link.active {
        font-size: 11px !important;
    }

    .generate-payroll-tables{
        width: 800px;
    }
}