.modal {
  z-index: 2000 !important;
}
.modal-backdrop {
  z-index: 2000 !important;
}
.modal-title {
  font-size: 1rem !important;
}

.display-reason{
  max-width: 18%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.uploaded-documents{
  text-decoration: underline;
  color: blue;
}
.display-filename{
  max-width: 300px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.employee-profile{
  cursor: pointer;
}

.title-alignment{
  margin-left: -15px;
}

.green-text{
  color: #1EA66D;
}
