ul {
    list-style: none;
}

ul li.allow:before{
    content: '✓  ';
}

ul li.not-allow:before{
    content: '*  ';
}