.sigPadContainer {
    background-color: rgb(238, 235, 235);
    padding: 0 10px 10px;
    border: 2px solid #F26930;
    border-radius: 10px;
}
.sigCanvas{
    width: 100%;
    height: 200px;
}

.sigPadContainer hr{
    margin-bottom: unset;
    background-color: gainsboro;
}
.sigPadContainer button {
    border: none;
    margin-left: auto;
    color: rgb(78, 75, 75);
    padding: 0;
    display: block;
    margin-top: 5px;
}
.sigPadContainer button:hover {
    background-color: unset;
    color: black;
}