.page-item span.page-link {
    color: orange;
}

.page-item a.page-link span {
    color: orange;
}



