.not-found-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-color: #f8f9fa;
    color: #343a40;
    text-align: center;
}

.not-found-title {
    font-size: 6rem;
    font-weight: bold;
    margin: 0;
}

.not-found-message {
    font-size: 1.5rem;
    margin: 1rem 0;
}

.not-found-home-link {
    font-size: 1.2rem;
    color: #007bff;
    text-decoration: none;
    border: 2px solid #007bff;
    padding: 0.5rem 1rem;
    border-radius: 5px;
    transition: background-color 0.3s, color 0.3s;
}

.not-found-home-link:hover {
    background-color: #007bff;
    color: #fff;
}