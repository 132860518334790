.container-line{
    /*border: 1px solid #F26930;*/
    /*border-radius: 20px;*/
    /*margin: 2px;*/
    margin-bottom: 20px;
}

.form-check-input{
    border-radius: 50px;
    position: relative;
}

.form-check-input:checked{
    /*background: #F26930;*/
    position: relative;
    border: 1px solid orange;
    background-color: #F26930;
}