.profile-picture{
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center;
}

.upload-icon{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
}

.profile-box{
    border: 1px solid black;
    width: 150px;
    height:110px;
    cursor: pointer;
    text-align: center;
    position: relative;
    transition: 0.6s;
    /*background-image: url("/Octaneware1.png");*/
    border-radius: 15px;
}

.profile-box:hover{
    background-color:#E3E3E3;

}