#bg {
  background: rgb(253, 50, 16);
  background: linear-gradient(
    273deg,
    rgba(253, 50, 16, 0.5637605383950455) 0%,
    rgba(211, 101, 12, 0.8438725832129726) 12%,
    rgba(252, 173, 4, 0.342472022988883) 93%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#fd3210", endColorstr="#fcad04", GradientType=1);
  /*background-color: #f39f86;*/
  /*background-image: linear-gradient(315deg, #f39f86 0%, #f9d976 74%);*/

  padding: 0;
}

div.box-shadow {
  text-align: center;
}

#timestamp {
  font-weight: bold;
}

#login_container {
  border-radius: 13px;
  margin: 0;
  padding: 0;

  /*background: linear-gradient(90deg, white 00%, cyan 70%);*/
}

#logo {
  margin-top: 80px;
  height: 140px;
}

.alert {
  border-radius: 3px !important;
}

.is-danger{
  border: 1px solid;
  color: #D8000C;
  background-color: #FFBABA;
}

@media screen and (max-width: 678px) {
  .logo {
    height: 90px !important;
    width: auto !important;
  }

  #left-side {
    margin-top: -20px;
  }
  #gradient_div {
    margin-top: -20px;
    border-top-left-radius: 13px !important;
    border-bottom-left-radius: 10px !important;
  }
}

#gradient_div {
  border-top-right-radius: 13px !important;
  border-bottom-right-radius: 10px !important;
  background: transparent linear-gradient(0deg, #f26930 0%, #fba91a 100%) 0% 0% no-repeat
    padding-box;
}

#sign_in_label {
  font-size: 13px !important;
}

.vertical-center {
  margin: 0;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.login-inputs {
  border-radius: 13px !important;
}

.flb {
  font-size: 13px !important;
}
h6 {
  font-size: 13px !important;
}
.custom-orange {
  background-color: #fba91a;
  border-color: #fba91a;
}
.form_inputs {
  font-size: 12px !important;
}
/*#ipaddress {*/
/*    color: white;*/
/*}*/
