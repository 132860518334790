.modal {
    z-index: 2000 !important;
}
.modal-backdrop {
    z-index: 2000 !important;
}
.modal-title {
    font-size: 1rem !important;
}
#leavesummary_table {
    font-size: 12px !important;
}

.carousel-indicators {
    position: absolute;
    right: 0;
    bottom: -40px;
    left: 0;
    z-index: 2;
    display: flex;
    justify-content: center;
    padding: 0;
    margin-right: 15%;
    margin-bottom: 1rem;
    margin-left: 15%;
    list-style: none;
}
.carousel-control-prev {
    /* left: 420px;
    top: -400px; */
    left: -15px;
    width: 20px;
}
.carousel-control-next {
    /* top: -400px;
    right: 420px; */
    right: -15px;
    width: 20px;
}

.display-reason{
    max-width: 20%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}