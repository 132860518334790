.bullet-list{
    list-style-type: circle;
    margin-left: 20px;
}

.search-list:hover{
    text-decoration: underline;
    cursor: pointer;
}

.feature-tab{
    border: 1px solid black;
    border-radius: 10px !important;
}