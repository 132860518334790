.modal {
  z-index: 2000 !important;
}
.modal-backdrop {
  z-index: 2000 !important;
}
.modal-title {
  font-size: 1rem !important;
}

.display-employees{
  cursor: pointer;
  text-decoration: underline;
}
