.modal {
  z-index: 2000 !important;
}

.modal-backdrop {
  z-index: 2000 !important;
}

.modal-title {
  font-size: 1rem !important;
}

.employee-name{
  cursor: pointer;
  text-decoration: underline;
  text-decoration-color: black;
}

.employee-name-label{
  font-size: 115%;
}

.net-pay-label{
  text-decoration: underline;
  text-decoration-color: black;
}

.table-row-middle-align {
  vertical-align: middle;
}

@media only screen and (max-width: 600px) {
  ul.payroll-tabs li.nav-item {
    display: flex;
    flex-basis: 20%;
    width: 20%;
  }
}

.taxable-details{
  cursor: pointer;
  text-decoration: underline;
}



/*small {*/
/*    font-size: 12px !important;*/
/*}*/

/*ul {*/
/*    margin-left: 300px!important;*/
/*}*/
