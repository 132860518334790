.modal {
  z-index: 2000 !important;
}
.modal-backdrop {
  z-index: 2000 !important;
}
.modal-title {
  font-size: 1rem !important;
}
.breadcrumb-item {
  text-decoration: none !important;
  color: #f26930 !important;
}

.url-name{
  cursor: pointer;
}

.url-name:hover{
  text-decoration: underline;
}

.clickable-activity{
  cursor: pointer;
}

.clickable-activity-label{
  text-decoration: underline;
}
