.modal {
  z-index: 2000 !important;
}
.modal-backdrop {
  z-index: 2000 !important;
}
.modal-title {
  font-size: 1rem !important;
}

.clickable{
  text-decoration: underline !important;
  color: black !important;
}

.clickable:hover{
  cursor: pointer;
}
