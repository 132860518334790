.modal {
  z-index: 2000 !important;
}
.modal-backdrop {
  z-index: 2000 !important;
}
.modal-title {
  font-size: 1rem !important;
}
#leavesummary_table {
  font-size: 12px !important;
}

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: -40px;
  left: 0;
  z-index: 2;
  display: flex;
  justify-content: center;
  padding: 0;
  margin-right: 15%;
  margin-bottom: 1rem;
  margin-left: 15%;
  list-style: none;
}
.carousel-control-prev {
  /* left: 420px;
  top: -400px; */
  left: -15px;
  width: 20px;
}
.carousel-control-next {
  /* top: -400px;
  right: 420px; */
  right: -15px;
  width: 20px;
}

.display-reason{
  max-width: 20%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.reason-text {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  line-height: 1.5em; /* Adjust line height to match two lines */
  height: 3em; /* Explicit height to restrict to two lines */
  /*min-height:4em; !* Ensures minimum height for consistency *!*/
}

/*.custom-carousel .carousel-control-next-icon,*/
/*.custom-carousel .carousel-control-prev-icon {*/
/*  filter: brightness(0) saturate(100%) invert(51%) sepia(62%) saturate(467%) hue-rotate(334deg) brightness(101%) contrast(101%);*/
/*}*/

.custom-carousel .carousel-control-next,
.custom-carousel .carousel-control-prev {
  top: 50%;
  transform: translateY(-50%);
  width: 30px; /* Make it a bit larger */
  height: 30px; /* Make it a bit larger */
  background-color: #ff7043; /* Set the background color to the orange shade */
  border-radius: 50%; /* Make the background a circle */
  display: flex;
  align-items: center;
  justify-content: center;
}

.custom-carousel .carousel-control-next-icon,
.custom-carousel .carousel-control-prev-icon {
  width: 15px;
  height: 15px;
  background-color: transparent;
  color: white; /* Color the arrow white */
  font-size: 24px; /* Make the arrow icon larger and bold */
}


.custom-carousel .carousel-control-next-icon::after,
.custom-carousel .carousel-control-prev-icon::after {
  font-size: 20px;
  font-weight: bold;
  color: white;
}

.icon-confirm{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80px; /* Adjust size as needed */
  height: 80px; /* Adjust size as needed */
  border: 3px solid #f44336; /* Red border */
  border-radius: 50%; /* Circle shape */
  background-color: #fff; /* White background */
  color: #f44336; /* Red icon color */
  font-size: 36px; /* Adjust icon size */
  margin: 0 auto; /* Center the icon in its container */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Optional shadow for effect */
}



