@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400&display=swap');

:root {
  background-color: #F5F6FA;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Roboto', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  //overflow: hidden;
  //overflow-y: hidden;

  //overflow-y: hidden; /* Hide vertical scrollbar */
  overflow-x: hidden; /* Hide horizontal scrollbar */
  background-color: var(--bg-color) !important;
  color: var(--color) !important;
}

///* Small devices (portrait tablets and large phones, 600px and up) */
//@media only screen and (min-width: 600px) {
//  .App{
//    overflow-y: scroll!important; /* Hide vertical scrollbar */
//    overflow-x: scroll!important; /* Hide horizontal scrollbar */
//
//  }
//}


code {
  font-family: 'Roboto', sans-serif !important;
}

//THEME SWITCHER
.color-switcher {
  width: 10rem;
  border-bottom-left-radius: 8px;
  border-top-left-radius: 8px;
  right: 0;
  //background-color: #e4e7f2;
  //position: absolute !important;

  //position: absolute;
  //z-index: 0;
  //top: 50% !important;
  //transform: translate(100%, -50%);

  //transition: all .5s ease-in-out;
}

//.color-switcher--open{
//  transform: translate(0, -50%);
//}

.color-list {
  display: grid;
  grid-template-columns: auto auto auto auto;
  gap: .5rem;
}

.heading {
  font-size: 13px !important;

}

.color-item {
  width: 2.5rem !important;
  height: 2.5rem !important;
  text-align: center;
  border-radius: 50% !important;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19) !important;
  //background-color: var(--bg-color) !important;
}

.color-item:hover {
  opacity: .8 !important;
}

//.cog-btn {
//  width: 3rem;
//  height: 3rem;
//  background-color: #e4e7f2;
//  border: none;
//  position: absolute;
//  left: 0;
//  top: 50%;
//  transform: translate(-100%, -50%);
//  display: grid;
//  place-items: center;
//  border-bottom-left-radius: 8px;
//  border-top-left-radius: 8px;
//;
//
//}


.links {
  text-decoration: none !important;
  color: black !important;
}

.navigation {
  color: var(--color) !important;
}

//HIDE SCROLLBAR
.content::-webkit-scrollbar-track {
  /*background-color: #373B53;*/
  /*background-color: #1E7961;*/

}

.content::-webkit-scrollbar {
  width: 12px;
  background-color: inherit;
}

.content::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: transparent;
}


.left_logo {
  background-color: var(--bg-color) !important;
  color: var(--color) !important;
}

//@media only screen and (max-width: 600px) {
//  .top_row {
//    flex-flow: column-reverse;
//  }
//  #left_logo{
//    visibility: hidden!important;
//    clear: both;
//    display: none!important;
//
//  }
//}

//.fab-icon-holder {
//  align-items: center;
//  justify-content: start;
//  font-size: 15px;
//  padding: 10px;
//  width: 250px;
//  height: 45px;
//  border-radius: 25px;
//  background: #016fb9;
//  color: white;
//  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.2);
//}
//
//.fab-icon-holder:hover {
//  opacity: 0.8;
//}
//
//
//.fab-icon-holder .links {
//  display: flex;
//  align-items: center;
//  justify-content: start;
//  height: 100%;
//  font-size: 15px;
//  padding: 10px;
//  color: white;
//}

//.fab {
//  width: 45px;
//  height: 45px;
//  background: black;
//  box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
//  transform: scale(1);
//
//  animation: pulse 1s infinite;
//
//  //background-color: var(--bg-color) !important;
//  //color: var(--color)!important;
//}


//PULSE EFFECT

//@keyframes pulse {
//  0% {
//    transform: scale(0.95);
//    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
//  }
//
//  70% {
//    transform: scale(1);
//    box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
//  }
//
//  100% {
//    transform: scale(0.95);
//    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
//  }
//}

//.fab-options {
//  list-style-type: none;
//  margin: 0;
//  position: absolute;
//  top: 70px;
//  opacity: 0;
//  transition: all 0.3s ease;
//  transform: scale(0);
//  transform-origin: left top;
//  background-color: var(--bg-color) !important;
//}
//
//.fab:hover + .fab-options, .fab-options:hover {
//  opacity: 1;
//  transform: scale(1);
//
//}
//
//.fab-options li {
//  display: flex;
//  justify-content: flex-end;
//  padding: 5px;
//  color: purple;
//}
//
//.fat {
//  text-decoration: none !important;
//  color: var(--color) !important;
//}

.custom-orange {
  //background-color: #FBA91A;
  border-color: #FBA91A;
}

tbody {
  font-size: 12px !important;
}

thead {
  font-size: 14px !important;
}

.breadcrumb-item {
  text-decoration: none !important;
  font-size: 12px !important;
  color: black !important;
}

.breadcrumb-item {
  text-decoration: none !important;
  color: #F26930 !important;
}

.add-new-btn {
  font-size: 12px !important;
}


.search-input {
  border-left: none !important;
}

.modal {
  font-size: 14px !important;
}

.update-btn {
  font-size: 12px !important;
}

.bordered-card {
  border-radius: 15px !important;
}

.bordered-card-tabs {
  border-top-right-radius: 15px !important;
  border-bottom-left-radius: 15px !important;
  border-bottom-right-radius: 15px !important;
  border-top-left-radius: 0 !important;
  //border-radius: 15px !important;
}

table {
  font-variant-numeric: lining-nums tabular-nums !important;
}

.text-right {
  text-align: end !important;
}

.btn-leave-application {
  border-bottom-left-radius: 15px !important;

}

th {
  vertical-align: middle !important;
}


.bordered-tabs-left {
  //-webkit-box-shadow: 0 0px 4px 4px #232323;
  //-moz-box-shadow: 0 0px 4px 4px #232323;
  //box-shadow: -3px 0px 5px -2px rgb(1 0 0 / 30%) !important;
  box-shadow: -2px 0px 2px 0px rgb(1 0 0 / 15%) !important;
  border-top: 1px solid white !important;
}


.bordered-tabs-right {
  box-shadow: 2px 0px 2px 0px rgb(1 0 0 / 15%) !important;
  //border-top: 1px solid white !important;
  //box-shadow: 3px 0 4px -3px #888 !important;
}

.bordered-tabs-bottom {
  box-shadow: 0px -2px 0.3px 0px rgb(1 0 0 / 15%) !important;
  //box-shadow: 3px 0 4px -3px #888 !important;
}

.bordered-tabs-top {
  border-top: solid #DEE2E6 1px !important;
}

//CUSTOM TABLE HOVER
tr:hover {
  background: #ECECEC !important;
}

thead tr:hover {
  background: transparent !important;
}

tr.no-item {
  text-align: center;
  background: #ececec !important;
}

#main {
  width: calc(100% - 320px) !important;
  min-height: 100vh !important;
}

.wrapper {
  width: 100%;
  box-sizing: border-box;
  //padding: 0px!important;
  overflow: auto;
  overflow-y: hidden;
}

//TABS
.nav-tabs .nav-link {
  width: 130px !important;
  max-width: 200px !important;
  font-size: 14px !important;
  text-decoration: none !important;
  background-color: #E3E3E3 !important;
  color: black !important;
  font-weight: bold;
  padding: 10px !important;
  border-top-right-radius: 15px !important;
  border-top-left-radius: 15px !important;
  margin-left: 0 !important;
  margin-right: 5px !important;
  overflow: hidden;
  white-space: nowrap;
  //border-bottom: transparent !important;

  //box-shadow: 0px 1px 2px 1px #888 !important;

}

.nav-tabs {
  border-bottom: #FFFFFF !important;
  overflow: auto;
  overflow-y: hidden;
}

.nav-tabs .nav-link.active {
  width: 130px !important;
  max-width: 200px !important;
  border-top-right-radius: 15px !important;
  background-color: white !important;
  border-top-left-radius: 15px !important;
  margin-left: 0 !important;
  margin-right: 5px !important;
  font-size: 14px !important;
  color: #F26930 !important;
  overflow: hidden;
  white-space: nowrap;
  box-shadow: 0px 2px 2px .05px #888 !important;
  //border-bottom: transparent !important;
  //border: transparent!important;
  //border: transparent;
}

//REMOVE THE THICK LINE UNDER THE THEAD
.table > :not(:first-child) {
  border-top: 0 !important;
}

//.table >:not(:last-child){
//  background: #ff0000;
//}
//
//.table > (:last-child) {
//  border-bottom: 2px !important;
//}

//REMOVE UNDERLINE AND DEFAULT BREADCRUMBS COLORS
a {
  color: #A7A9AC !important;
  text-decoration: none !important;
}

.tooltip {
  z-index: 2000 !important;
}

.page-item .page-link {
  font-size: 12px !important;
  font-weight: bold !important;
  padding-left: 10px !important;
  padding-right: 10px !important;
  border-radius: 4px !important;

}

.page-item.active .page-link {
  z-index: 3;
  //color: #fff !important;
  border-color: #F26930 !important;
  background-color: #F26930 !important;
}

tr.hidden-table:hover > td {
  background-color: white !important;
}

tr.hidden-table > td {
  padding: 0px !important;
}

.tab::before {
  content: '';
  background-color: #ccc;
  height: 1px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

footer {
  bottom: 0;
}

// XX-Large devices (larger desktops, 1400px and up)
.bordered-tabs-top-xxl {
  border-top: solid white 1px !important;
}

.required:after {
  content: "*";
  color: red;
  font-size: 13px !important;
}

.is-invalid~.invalid-feedback, .is-invalid~.invalid-tooltip, .was-validated :invalid~.invalid-feedback, .was-validated :invalid~.invalid-tooltip {
  display: block;
  top: 26px;
  height: 25px;
  background: none;
  color: #dc3545;
  font-size: 13px;
}

.login-error {
  text-align: left;
  color: #fff;
  font-size: 14px;
  margin-top: -30px;
}

.modal-confirm .modal-content * {
  padding: 4px;
  border-radius: 5px;
  text-align: center;
}
.modal-confirm .modal-header {
  border: none;
}
.modal-confirm .modal-header .btn-close {
  position: absolute !important;
  top: 10px !important;
  right: 10px !important;
}
.modal-confirm .modal-body {
  color: #999;
  height: 35px;
}
.modal-confirm .modal-footer {
  border: none;
}
.modal-confirm .icon-confirm {
  width: 76px;
  height: 76px;
  border-radius: 50%;
  line-height: 70px;
  transition: 0.2s;
  background-color: white;
  border: 3px solid #f15e5e;
  color: #f15e5e;
  font-size: 28px;
  display: inline-block;
  margin-top: 13px;
}
.modal-confirm .icon-success {
  width: 76px;
  height: 76px;
  border-radius: 50%;
  line-height: 70px;
  transition: 0.2s;
  background-color: white;
  border: 3px solid #f37845;
  color: #f37845;
  font-size: 28px;
  display: inline-block;
  margin-top: 13px;
}
.modal-confirm .icon-error {
  width: 76px;
  height: 76px;
  border-radius: 50%;
  line-height: 70px;
  transition: 0.2s;
  background-color: white;
  border: 3px solid #f15e5e;
  color: #f15e5e;
  font-size: 28px;
  display: inline-block;
  margin-top: 13px;
}
.modal-confirm .icon-box {
  width:100%;
  align-items: center;
}
.modal-confirm h5{
  color: #999;
  font-size: 17px;
}

.capture-icon {
  height: 300px;
  border: 3px solid #F26930;
  border-radius: 10px;
  padding-left: 10px;
  padding-top: 120px;
  margin-left: 150px;
  margin-right: 150px;
}

.capture {
  height: 300px;
  border: 1px solid #F26930;
  border-radius: 10px;
  margin-left: 150px;
  margin-right: 150px;
  object-fit: cover;
}

.border-right {
  border-right: 2px solid #888;
}

.btn-primary:hover {
  background: #F26930 !important;
  box-shadow: 0 0 0 0.2rem rgba(232, 143, 2, 0.25);
}
.btn-outline-primary:hover {
  color: #F26930 !important;
  background: none !important;
  box-shadow: 0 0 0 0.2rem rgba(232, 143, 2, 0.25);
}

@media only screen and (max-width: 600px) {
  .app-bar-open {
    width: 100% !important;
  }
  .app-bar-close {
    width: calc(100% - 56px) !important;
  }
  .drawer-open {
    display: none !important;
  }
  .drawer-close {
    display: show !important;
  }
  .css-1xb8cnv {
    padding-left: 15px !important;
    padding-right: 7px !important;
  }

  .capture-icon {
    border: 2px solid #F26930 !important;
    margin-left: 0px !important;
    margin-right: 0px !important;
  }
  .capture {
    margin-left: 0px !important;
    width: 100% !important;
    height: 230px !important;
  }
}

@media only screen and (min-width: 800px) {

  tbody {
    font-size: 12.6px !important;
  }
  #pagination-payroll-list {
    margin-top: 5px !important;
  }
}

@media only screen and (min-width: 1600px) {
  .bordered-tabs-top-xxl {
    border-top: solid #DEE2E6 1px !important;
  }
  .nav-tabs .nav-link {
    width: 120px !important;
    max-width: 200px !important
  }
  .nav-tabs .nav-link.active {
    width: 120px !important;
    max-width: 200px !important
  }


  //.text-truncate{
  //  overflow:auto;
  //}


}


