.container-root{
    width: 180px;
    height: 150px;
    border: 3px solid #ff7043;
    /*display: flex;*/
    /*justify-content: center;*/
    position: relative;
    margin: auto;
    border-radius: 20px;
    cursor: pointer;
    background-color: #fff5f2;
}

.container-root:hover{
    background-color: transparent;
}

.employee-label{
    /*display: inline-block;*/
    /*white-space: nowrap;*/
    font-size: 15px; /* Set the original font size */
}

.designation-label{
    font-size: 13px;
}

.box {
    display: grid;
    place-items: center; /* Center items in both directions */
    height: 130px; /* Adjust the height as needed */
    width: 180px;  /* Adjust the width as needed */
    border: none; /* Box border for visibility */
    position: relative;
    margin: auto;
}


.line{
    width: 2px;
    height: 130px; /* Full box height */
    background-color: #ff7043;
}

/*.scrollable-card-body{*/

/*    height: 100vh;*/
/*}*/

.tree-node {
    overflow: hidden;
    transition: height 0.3s ease, opacity 0.3s ease;
    height: 0; /* Initially hide the node */
    opacity: 0;
}

.tree-node.show {
    height: 130px; /* Adjust height to fit content */
    opacity: 1;
}


/* CSS transition for smooth show/hide effect */
.node-children {
    transition: max-height 0.5s ease-out, opacity 0.5s ease-out;
    max-height: 1000px; /* Default height, adjust based on your content */
    overflow: hidden;
    opacity: 1;
}

/* CSS for hiding node children */
.node-children.hidden {
    max-height: 0;
    opacity: 0;
    pointer-events: none;
}

.zoom-controls {
    display: flex;
    justify-content: right;
    margin-top: 5px; /* Adjust spacing as needed */
    gap: 5px;
    margin-bottom: 10px;
}

.zoom-buttons {
    border: 1px solid black;
    z-index: 1000;
}

.zoom-buttons:hover {
    border: 1px solid black;
}

.scrollable-div{

    margin-top: 10px;
    overflow-x: auto;
    overflow-y: auto;
    height: 100vh;
    /*overflow: auto;*/
    width: 100%;
    /*height: 100vh;*/
    /*transform-origin: 0 0;*/
    /*transition: transform 0.2s ease-in-out;*/
}

.tree-container {
    /*transform: scale(var(--zoom, 1));*/
    transform-origin: 0 0;
    transition: transform 0.2s ease-in-out;
}

/* Styles for the bottom center button */
.bottom-center-button {
    position: absolute;
    top: 0; /* Adjust this value to move the button up or down */
    /*left: 50%;*/
    right: 5%;
    /*transform: translateX(-50%);*/
    /*padding: 10px 20px;*/
    /*background-color: #007bff;*/
    color: #ff7043;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 20px;
    /*border: 1px solid #ff7043;*/
}

/* Styles for the bottom center button */
.bottom-center-text {

    display: inline-flex;
    align-items: center;
    justify-content: center;
    position: relative;
    cursor: pointer;
    font-size: 12px;
    padding: 5px; /* Add padding to ensure there is space around the text */
    --size: calc(1em + 12px); /* Base size depending on font size */
    width: var(--size);
    height: var(--size);
    border-radius: calc(var(--size) / 2);
    text-align: center;
    font-weight: bold;
    white-space: nowrap; /* Prevent text from wrapping */
}

.bottom-center-button:hover {
    color: #ff7043;
}

.tree-container .tree-node .oc-v-line {
    height: 100px; /* Default height for multiple children */
    position: relative;
}

.tree-container .tree-node.oc-single-child .oc-v-line {
    height: 200px; /* Double the height when there's only one child */
}

.tree-container .oc-h-line {
    position: relative;
}

.tree-container .oc-h-line::before {
    content: '';
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 2px; /* Match the lineWidth */
    height: 100%;
    background-color: #ff7043; /* Match the lineColor */
}

.tree-container .oc-v-line::before {
    content: '';
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 2px; /* Match the lineWidth */
    height: 100%;
    background-color: #ff7043; /* Match the lineColor */
}


