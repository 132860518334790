.modal {
  z-index: 2000 !important;
}
.modal-backdrop {
  z-index: 2000 !important;
}
.modal-title {
  font-size: 1rem !important;
}
.breadcrumb-item {
  text-decoration: none !important;
  color: #f26930 !important;
}

.center-align{
  vertical-align: middle;
}

.table-scheduling tr:hover {
  background-color: unset !important;
}


.scheduling-container{
  border-color: #F26A30;
  border-width: 1px;
  height: 130px;
  cursor: pointer;
}

.table-border-right{
  border-right: 1px solid #dee2e6 !important;
}

.table-no-border{
  border: none;
}

table.table-scheduling .form-select{
  padding: 0.375rem 1rem 0.375rem 0.75rem;
  background-position: right -0.25rem center;
  background-size: 25px 7px;
}

.copy-paste-button:hover{
  background-color: unset !important;
  box-shadow: unset;
}

.branch-name{
  cursor:pointer;
}

/*=====================*/
/*.sticky-col {*/
/*  position: sticky;*/
/*  background-color: white !important;*/
/*  z-index: 2;*/
/*}*/
/*.first-col {*/
/*  left: 0px;*/
/*  width: 110px;*/
/*  min-width: 110px;*/
/*  max-width: 110px;*/
/*  !*border-top: 1px;*!*/
/*  !*border-left: 1px;*!*/
/*  !*border-right: 1px;*!*/
/*  !*border: 1px solid #dee2e6;*!*/
/*}*/
/*.second-col {*/
/*  left: 105px;*/
/*  width: 125px;*/
/*  min-width: 125px;*/
/*  max-width: 125px;*/
/*  !*border-top: 1px;*!*/
/*  !*border-right: 1px;*!*/
/*  !*border: 1px solid #dee2e6;*!*/
/*}*/
/*th.sticky-col {*/
/*  border-bottom-width: 1px   !important;*/
/*}*/
.table-scheduling {
  border-collapse: separate;
  border-spacing: 0;
  overflow-x: auto;
}

.table-scheduling th {
  border: 1px solid #dee2e6;
  border-left: none;
}
.table-scheduling th:nth-child(1) {
  border: 1px solid #dee2e6;
}

.table-scheduling th:nth-child(1),
.table-scheduling th:nth-child(2) {
  border-bottom: none;
}

.table-scheduling td {
  border: none;
}

.table-scheduling td:nth-child(1) {
  border: 1px solid #dee2e6;
  border-right: none;
  border-bottom: none;
}

.table-scheduling td:nth-child(2) {
  border: 1px solid #dee2e6;
  border-bottom: none;
}
.table-scheduling td:nth-last-child(1) {
  border-right: 1px solid #dee2e6;
}

.table-scheduling tr:nth-last-child(1) td{
  border-bottom: 1px solid #dee2e6;
}

.table-scheduling th:nth-child(1),
.table-scheduling td:nth-child(1){
  position: sticky;
  left: 0;
}
.table-scheduling th:nth-child(2),
.table-scheduling td:nth-child(2){
  position: sticky;
  left: 110px;
  /*box-shadow: 10px 0px 0px 0px #888;;*/
}

.table-scheduling th,
.table-scheduling td:nth-child(1),
.table-scheduling td:nth-child(2) {
  background-color: white;
  z-index: 1;
}

option[value="0"][disabled] {
  display: none;
}

.display-notes{
  display: -webkit-box;
  max-width: 300px;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.employee-dropdown{
  z-index: 2 !important;
}

@media (max-width:960px) {
  .employee-dropdown{
    z-index: 0 !important;
  }

  .switch-button-active{
    z-index: 0 !important;
  }

  .sticky-col {
    z-index: 0 !important;
  }
}

.floating-button-right {
  position: absolute;
  /*top: 50%;*/
  right: 25px; /* Adjust the distance from the right as needed */
  /*transform: translateY(-50%);*/
  padding: 10px 20px;
  background-color: #F26A30;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  z-index: 2;
  border-radius: 45%;
  transition: 0.4s;
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19);
  font-size: 200%;
  line-height: 27px;
  opacity: 70%;
}

.floating-button-right:hover {
  transition: 0.2s;
  opacity: 100%;
  /*background-color: #f58251;*/
  /*border: 0.5px solid #F26A30;*/
  /*color: #F26A30;*/
}

.floating-button-left {
  position: absolute;
  /*top: 50%;*/
  left: 265px; /* Adjust the distance from the right as needed */
  /*transform: translateY(-50%);*/
  padding: 10px 20px;
  background-color: #F26A30;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  z-index: 2;
  border-radius: 45%;
  transition: 0.4s;
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19);
  font-size: 200%;
  line-height: 27px;
  opacity: 70%;
}

.floating-button-left:hover {
  transition: 0.2s;
  opacity: 100%;
  /*background-color: #f58251;*/
  /*border: 0.5px solid #F26A30;*/
  /*color: #F26A30;*/
}

.switch-view{
  /*color: white;*/
}

/*.switch-view:checked{*/
/*  color: white;*/
/*  background-color: orange !important;*/
/*}*/

.switch-view-button{
  color: black;
  background-color: white !important;
}

/*.switch-view-button-default.active{*/
/*  color: white;*/
/*  background-color: orange !important;*/
/*}*/

.switch-view-button{
  color: black;
  background-color: transparent !important;
  border: 1px solid #a7a9ac;
  vertical-align: middle !important;
}
.switch-view-button:hover{
  color: white;
  background-color: unset !important;
  vertical-align: middle !important;
}

.switch-button-active{
  color: #f26930;
  background-color: transparent !important;
  border: 2px solid #f26930;
}

.switch-button-active:hover{
  color: white;
  background-color: unset !important;
}

label.switch-button-active{
  color: white;
}

.days-of-the-week{
  width: 14.28%;
}

.table-calendar th{
  border: 1px solid #dee2e6;
}

.table-calendar td{
  border: 1px solid #dee2e6;
  height: 100px;
}

.weekend{
  color: #F26A30;
}

.table-calendar tr:hover {
  background-color: unset !important;
}

.not-included-month{
  opacity: 60%;
}

/*.table-calendar td:hover {*/
/*  background-color: #ff9669 !important;*/
/*}*/

.custom-navigation {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
}

.navigation-button {
  padding: 2px 8px;
  margin: 0 10px;
  font-size: 16px;
  cursor: pointer;
  border: 1px solid gray;
}

.navigation-button:hover {
  border: 1px solid gray;
}

.current-item {
  font-size: 15px;
  /*font-weight: bold;*/
  margin-right: 10px;
  margin-left: 10px;
}

.circle
{
  width:30px;
  height:30px;
  border-radius:15px;
  font-size:12px;
  color:white;
  line-height:30px;
  text-align:center;
  background:#F26A30
}

.current-day{
  border: 2px solid #f26930 !important;
}