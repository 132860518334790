@media only screen and (max-width: 600px) {
    .MuiButtonBase-root {

        font-size: 11px !important;
    }

    .tab-list-item{
        display: flex;
        flex-basis: 14.28%;
        width: 14.28%;
    }

    .employee-list{
        width: 900px
    }
}